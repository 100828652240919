import jwtDecode from "jwt-decode";
import moment from "moment";

export const getTimestamp = (date) => new Date(date || Date.now()).getTime();

export const getFileredRoutes = (routes, permissions) => routes.filter(route => {
  let and = true;

  if (route.children) route.children = getFileredRoutes(route.children, permissions);

  (route.requiredPermissions || []).forEach(p => {
    if (Array.isArray(p)) {
      let or = false;
      p.forEach(p => or |= permissions[p]);

      and &= or;
    } else {
      and &= permissions[p];
    }
  });

  return and;
});

export const whitelistLanguages = ['en', 'fr'];

export const generateUniqueId = () => Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);

export const setTempKeys = (data = []) => data.map(d => ({ ...d, key: d.key || generateUniqueId() }));

export const removeTempKeys = (data = []) => data.map(d => {
  delete d.key;
  return d;
});

export const getTokenData = () => {
  const token = localStorage.getItem("token");
  return token ? jwtDecode(token) : {};
}

export const getLetterByNumber = (number) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letters[number];
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const datePickerReadableFormat = (value) => (
  value ? moment(value).format('DD MMM, YYYY') : null
);