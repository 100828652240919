import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import googlePlay from "@iso/assets/images/googleplaybadge.png";
import appleStore from "@iso/assets/images/appStore.png";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import SidebarProvider from "./sidebarContext";
import { useTranslation } from "react-i18next";
import { whitelistLanguages } from './utils/helpers';

import "./App.css";
import Backdrop from "./components/Backdrop";

const App = () => {
  const { i18n } = useTranslation();

  const setLanguage = () => {
    const lng = i18n.language;
    if (whitelistLanguages.indexOf(lng) === -1) i18n.changeLanguage('en');
  }
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  
  useEffect(() => {
    if (isMobile && !isTablet) {
      if (isAndroid) {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.safetyqube"
        );    
      } else if (isIOS) {
          window.location.replace(
            "https://apps.apple.com/lt/app/safetyqube/id1576481295"
          );
      }
    }

    localStorage.setItem("redLoginVariable",true);
    setLanguage();

    window.addEventListener("offline", () => setIsOffline(true));
    window.addEventListener("online", () => setIsOffline(false));
  }, []);

  const renderUI = () => {
    if (isMobile && !isTablet) {
      if (isAndroid) return (
        <div className="playLink">
            <img src={googlePlay} className="imgElement-app" alt="tile"></img>
        </div>
      );
      else if (isIOS) return (
        <div className="playLink">
            <img src={appleStore} className="imgElement-app" alt="tile"></img>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <AppProvider>
          <>
            <SidebarProvider>
              <GlobalStyles />
              <Routes />
              {isOffline && <Backdrop message="You're offline. Please connect to internet." />}
            </SidebarProvider>
          </>
        </AppProvider>
      </Provider>
    );
  }

  return (
    <div className="App">
      {renderUI()}
    </div>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
