export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGET_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
  PAGE_404: "/404",
  PAGE_500: "/500",
  AUTH0_CALLBACK: "/auth0loginCallback",
  AUTH: "/auth",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
  ARCHIVE: "/closed",
  CUSTOMFIELDS: "/custom-fields",
  RULES: "/rules",
  ROLES: "/roles",
  NOTIFICATIONGROUPS: "/user-groups",
  USERMANAGEMENT: "/user-management",
  LEADERBOARD: "/leaderboard",
  CKECKLIST: "/checklist",
  ALLISSUES: "/in-progress",
  RAISEINCIDENT: "/raise-incident",
  RAISEHAZARD: "/hazard/raise",
  RAISE: "/raise",
  REPORTS: "/reports",
  DEEPDIVE: "/deep-dive",
  MYACTIONS: "/my-actions",
  HOME: "/home",
  NOTIFICATION: "/notification",
  DROPZONE: "/dropzone",
  PROFILE: "/my-profile",
};
