import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import jwtDecode from "jwt-decode";
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH,
    component: lazy(() =>
      import("@iso/containers/Auth")
    ),
  },
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() =>
  //     import("@iso/containers/Authentication/Auth0/Auth0Callback")
  //   ),
  // },
];
function PrivateRoute({ children, ...rest }) {
  // const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const isLoggedIn = localStorage.getItem("loggedIN");

  const APP_ID = 't0t8d38x';

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = (token && jwtDecode(token)) || {};
    if (isLoggedIn && decodedToken) {

      const intercomSettings = {
        app_id: APP_ID,
        name: `${decodedToken['firstname']} ${decodedToken['lastname']}`, // Full name
        email: decodedToken['email'], // Email address
        user_id: decodedToken['userId'], // current_user_id,
        SQ_User: true
      }
  
      window.Intercom('boot', intercomSettings);
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              // pathname: "/signin",
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
              <PrivateRoute path="/safety-qube">
                <Dashboard />
              </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
